import { gql, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { Formik, FormikProps } from "formik";
import { useFlags } from "launchdarkly-react-client-sdk";
import * as React from "react";
import { useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Switch from "react-switch";
import styled from "styled-components";
import * as Yup from "yup";
import { loggedInUser, tzOverride } from "../../apollo/cache";
import { SHOW_CALENDAR_OPTIONS, TZ_OVERRIDE } from "../../apollo/query";
import { CallContext, ModalContext } from "../../context/";
import { iconGoogleCalendar, iconOutlookCalendar } from "../../images/";
import { edit, google_meet_logo, refresh, zoom_logo } from "../../images/NewDesign";
import gmailLogo from "../../images/gmail-logo.svg";
import { openUploadWidget } from "../../services/cloudinary";
import { IIntegrations, IntegrationItem, OptionItem } from "../../types";
import { formatPhoneNumber } from "../../utils/format";
import { useFirstRender, useTabFocus } from "../../utils/hooks";
import { theme } from "../../utils/theme";
import { appToast } from "../../utils/toast";
import {
  BACKEND_URL,
  REACT_APP_GOOGLE_CALENDAR_SYNC_CALLBACK_URL,
  REACT_APP_MICROSOFT_CALENDAR_SYNC_CALLBACK_URL,
  TOKEN_PERSIST_KEY,
  TZ_OVERRIDE_PERSIST_KEY,
} from "../../utils/variables";
import { PhoenixInputField } from "../Field/Phoenix";
import { ProfileImage } from "../Segments";
import { IntegrationCardV2, updateLocalUser } from "../Smart";
import { AppErrorText, AppText, FlexDiv, Loading, NewAppButton, SkeletonBlock } from "../UI";
import { PhoenixAppButton, PhoenixIcon, PhoenixMultiSelect } from "../UI/Phoenix";
import { DisconnectIntegrationModal, RecordVoicemailModalComponent } from "../modal";
import { MixpanelActions } from "./../../services/mixpanel";
interface DetailsProps {
  email: string;
  alternate_email: string;
  phone_number: string;
}

const detailsSchema = Yup.object().shape({
  email: Yup.string().required("Email is required"),
  alternate_email: Yup.string().notRequired(),
  phone_number: Yup.string()
    .required("Please enter a valid phone number!")
    .max(10)
    .min(10)
    .matches(/^[0-9]*$/, "Phone number must be numeric"),
});

const FETCH_USER = gql`
  query fetchUser {
    fetchUser {
      id
      alternate_email
      email
      first_name
      last_name
      profile_image
      phone_number
      voicemail_url
      joinMe_connected
      timekit_type
      default_audio_input
      default_audio_output
      google_refresh_token
      checkUserCalendarStatus
    }
  }
`;

const UPDATE_DEFAULT_AUDIO = gql`
  mutation updateDefaultAudio($default_audio_input: String, $default_audio_output: String) {
    updateDefaultAudio(default_audio_input: $default_audio_input, default_audio_output: $default_audio_output) {
      id
      default_audio_input
      default_audio_output
    }
  }
`;

const UPDATE_USER_SETTINGS = gql`
  mutation UpdateUserSettings($email: String!, $alternate_email: String!, $phone_number: String!) {
    updateUserSettings(email: $email, alternate_email: $alternate_email, phone_number: $phone_number) {
      id
      email
      alternate_email
      phone_number
    }
  }
`;

interface CheckIntegrationStatusExpectedResponse {
  checkIntegrationStatus: {
    [key: string]: boolean;
  };
}
const CHECK_INTEGRATION_STATUS = gql`
  query checkIntegrationStatus {
    checkIntegrationStatus
  }
`;

const UPDATE_SYNC = gql`
  mutation updateSync($timekit_type: TIMEKITTYPE!) {
    updateSync(timekit_type: $timekit_type) {
      id
      timekit_type
    }
  }
`;

const AUTHORIZE_JOIN_ME = gql`
  mutation authorizeJoinMe($code: String!) {
    authorizeJoinMe(code: $code) {
      id
      joinMe_connected
    }
  }
`;
const UPDATE_USER_PROFILE_IMAGE = gql`
  mutation updateUserProfileImage($profile_image: String!) {
    updateUserProfileImage(profile_image: $profile_image) {
      id
      profile_image
    }
  }
`;

interface ParamTypes {
  sync_status: "google-success" | "microsoft-success" | undefined;
}

interface IntegrationStatus {
  enabledForOrg: boolean;
  enabledForUser: boolean;
}

export type IntegrationStatusRecord = Record<string, IntegrationStatus>;

const Settings: React.FC = () => {
  const { gmailSync, zoomIntegration, googleMeetIntegration } = useFlags();

  const { data: dataStatus } = useQuery<CheckIntegrationStatusExpectedResponse>(CHECK_INTEGRATION_STATUS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      console.log("Integration status fetched:", data);
    },
    onError: ({ message, name }) => {
      console.error(`Error in ${name}:`, message);
    },
  });

  const zoomIsEnabledOnOrg = dataStatus?.checkIntegrationStatus["Zoom"] ?? false;

  const googleMeetIsEnabledOnOrg = dataStatus?.checkIntegrationStatus["Google"] ?? false;

  const zoomIsEnabledOnUser = loggedInUser().checkUserCalendarStatus?.zoom_status === "active" ?? false;

  const googleMeetIsEnabledOnUser = loggedInUser().checkUserCalendarStatus?.google_status === "active" ?? false;

  const emailIntegrationsList: IntegrationItem[] = [
    {
      name: IIntegrations.Gmail,
      url: BACKEND_URL + "/gmail/getGmailUrl",
      logo: gmailLogo,
      description: "Sync communication with leads on Gmail account with Sellfire",
    },
  ];

  const ZoomIntegrationItem: IntegrationItem = {
    name: IIntegrations.Zoom,
    url: "N/A",
    logo: zoom_logo,
    description: "Enable demos held on Zoom and video recordings",
  };

  const GoogleMeetIntegrationItem: IntegrationItem = {
    name: IIntegrations.GoogleMeet,
    url: BACKEND_URL + "/google/redirect",
    logo: google_meet_logo,
    description: "Enable demos held on Google Meet and video recordings",
    options: {
      fieldMapping: false,
      settings: false,
      manageTemplates: false,
      importMissingLeads: false,
      integrationReports: false,
    },
  };

  const calendarIntegrationsList: IntegrationItem[] = [
    {
      name: IIntegrations.GoogleCalendar,
      url: REACT_APP_GOOGLE_CALENDAR_SYNC_CALLBACK_URL,
      logo: iconGoogleCalendar,
      description: "Sync Google calendar availability with Sellfire",
    },
    {
      name: IIntegrations.Outlook,
      url: REACT_APP_MICROSOFT_CALENDAR_SYNC_CALLBACK_URL,
      logo: iconOutlookCalendar,
      description: "Sync Outlook calendar availability with Sellfire",
    },
  ].filter(Boolean);

  const allIntegrationsList = [...calendarIntegrationsList, ...emailIntegrationsList].filter(Boolean);

  const [inputs, setInputs] = useState<OptionItem[]>([]);
  const [outputs, setOutputs] = useState<OptionItem[]>([]);
  const [selectedAudio, setSelectedAudio] = useState<"input" | "output" | undefined>(undefined);

  const { data: tzData } = useQuery(TZ_OVERRIDE);

  const {
    voiceMessageModal,
    setVoiceMessageModal,
    showDisconnectIntegrationModal,
    selectedIntegrationDisconnect,
  } = useContext(ModalContext);

  const { defaultInput, setDefaultInput, defaultOutput, setDefaultOutput, device } = useContext(CallContext);

  const [updateImage, { loading: loadingImage }] = useMutation(UPDATE_USER_PROFILE_IMAGE, {
    onCompleted() {},
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `updateSync GraphQL Error: ${message}`,
      });
    },
  });

  const [updateSync, { loading: loadingSync }] = useMutation(UPDATE_SYNC, {
    onCompleted({ updateSync }) {
      console.log("updateOneAutomatedCampaign: ", updateUserSettings);
      if (!updateSync) {
        appToast("Error: could not sync calendar");
        return;
      }
      if (updateSync.timekit_type === "Google") {
        appToast("Gmail Calendar Synced");
      }
      if (updateSync.timekit_type === "Microsoft") {
        appToast("Outlook Calendar Synced");
      } else {
        appToast("Calendar Synced");
      }
    },
    onError({ message }) {
      console.log("Error in updateSync: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `updateSync GraphQL Error: ${message}`,
      });
    },
  });

  const [updateUserSettings, { loading: loadingUp, error: errorUp }] = useMutation(UPDATE_USER_SETTINGS, {
    onCompleted({ updateUserSettings }) {
      console.log("updateOneAutomatedCampaign: ", updateUserSettings);
      if (!updateUserSettings) {
        appToast("Error: Something went wrong!");
        return;
      }
      appToast("Details updated!");
    },
    onError({ message }) {
      console.log("Error in updateUserSettings: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `updateUserSettings GraphQL Error: ${message}`,
      });
    },
  });

  const [authorizeJoinMe, { error: errorJoinMe }] = useMutation(AUTHORIZE_JOIN_ME, {
    onCompleted({ authorizeJoinMe }) {
      console.log("authorizeJoinMe: ", authorizeJoinMe);
      if (!authorizeJoinMe) {
        appToast("Error syncing JoinMe.");
        return;
      }
      appToast("Join me synced!");
      loggedInUser({ ...loggedInUser(), joinMe_connected: authorizeJoinMe.joinMe_connected });
    },
    onError({ message }) {
      console.error(message);
      appToast("Error syncing JoinMe.");
      Sentry.captureEvent({
        message: `authorizeJoinMe GraphQL Error: ${message}`,
      });
    },
  });

  const [updateDefaultAudio, { loading: loadingDefaultAudio }] = useMutation(UPDATE_DEFAULT_AUDIO, {
    onCompleted: ({ updateDefaultAudio }) => {
      appToast("Successfully updated default audio");
      console.log(updateDefaultAudio);
      selectedAudio === "input" && setDefaultInput(updateDefaultAudio.default_audio_input);
      selectedAudio === "output" && setDefaultOutput(updateDefaultAudio.default_audio_output);
    },
    onError: ({ message }) => {
      console.log("Error in updateDefaultAudio: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `updateDefaultAudio GraphQL Error: ${message}`,
      });
    },
  });

  const { data, loading, error, refetch } = useQuery(FETCH_USER, {
    fetchPolicy: "cache-and-network",
    onCompleted: ({ fetchUser }) => {
      setDefaultInput(fetchUser?.default_audio_input);
      setDefaultOutput(fetchUser?.default_audio_output);
      updateLocalUser(fetchUser);
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  // We want to recheck the sync status when the user focuses on the page.
  // The sync flow happens off platform and we currently do an optomistic state update for the UI
  useTabFocus(
    () => {
      refetch();
    },
    { buffer: 10000, waitTime: 1500 },
  );

  useEffect(() => {
    MixpanelActions.track("Lead Detail", {
      type: "Page Load",
    });
    let timekit_type = "";
    if (sync_status === "google-success") {
      timekit_type = "Google";
    } else if (sync_status === "microsoft-success") {
      timekit_type = "Microsoft";
    }
    if (!!timekit_type) {
      updateSync({
        variables: {
          timekit_type,
        },
      });
    }
  }, []);

  useEffect(() => {
    console.log("tz overrirde: ", tzData);
    if (tzData.tzOverride) {
      localStorage.setItem(TZ_OVERRIDE_PERSIST_KEY, "true");
    } else {
      localStorage.removeItem(TZ_OVERRIDE_PERSIST_KEY);
    }
  }, [tzData]);

  useEffect(() => {
    getAudioOptions({
      showToast: false,
    });
  }, [device?.audio?.availableInputDevices.size, device?.audio?.availableOutputDevices.size]);

  const params = new URLSearchParams(document.location.search.substring(1));
  const code = params.get("code");
  useEffect(() => {
    if (!joinMeSynced && code) {
      authorizeJoinMe({
        variables: {
          code: code,
        },
      });
    }
  }, []);

  const getAudioOptions = ({ showToast = false }: { showToast?: boolean }) => {
    if (!device) return;
    setInputs(
      Array.from(device?.audio?.availableInputDevices as any, ([_, value]) => ({
        label: value.label,
        value: value.deviceId,
      })).filter((item) => item.value !== "default"),
    );
    setOutputs(
      Array.from(device?.audio?.availableOutputDevices as any, ([_, value]) => ({
        label: value.label,
        value: value.deviceId,
      })).filter((item) => item.value !== "default"),
    );

    showToast && appToast("Audio options refreshed");
  };

  const handleProfileImageUpload = () => {
    openUploadWidget((error, res) => {
      if (error) {
        throw new Error("something went wrong");
      }
      if (!error && res && res.event === "success") {
        updateImage({ variables: { profile_image: res.info.public_id } });
        refetch();
      }
    });
  };

  const gmailSynced = data?.fetchUser?.timekit_type === "Google" ?? false;
  const outlookSynced = data?.fetchUser?.timekit_type === "Microsoft" ?? false;
  const zoomSynced = zoomIsEnabledOnOrg && zoomIsEnabledOnUser;
  const googleMeetSynced = googleMeetIsEnabledOnOrg && googleMeetIsEnabledOnUser;
  const joinMeSynced = data?.fetchUser?.joinMe_connected ?? false;
  const voicemail_url = data?.fetchUser?.voicemail_url || "";

  const isSynced = (item: IntegrationItem) => {
    switch (item.name) {
      case "Gmail":
        return !!data?.fetchUser?.google_refresh_token;
      case "JoinMe":
        return !!data?.fetchUser?.joinMe_connected;
      case "Google Calendar":
        return data?.fetchUser?.timekit_type === "Google";
      case "Outlook":
        return data?.fetchUser?.timekit_type === "Microsoft";
      case "Zoom":
        return zoomSynced;
      case "Google":
        return googleMeetSynced;
      default:
        return false;
    }
  };

  const selectedVideoIntegration = googleMeetIsEnabledOnOrg
    ? IIntegrations.GoogleMeet
    : zoomIsEnabledOnOrg
    ? IIntegrations.Zoom
    : undefined;

  const { sync_status } = useParams<ParamTypes>();

  const history = useHistory();

  const { data: orgData, loading: loadingOrgData } = useQuery(SHOW_CALENDAR_OPTIONS, {
    fetchPolicy: "cache-and-network",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const showCalendarOptions = orgData?.showCalendarOptions;

  const isFirstLoad = useFirstRender();

  const loadingIntegrations = useMemo(() => {
    return (
      loadingOrgData ||
      loading ||
      gmailSync === undefined ||
      showCalendarOptions === undefined ||
      googleMeetIntegration === undefined ||
      zoomIntegration === undefined
    );
  }, [loadingOrgData, loading, gmailSync, showCalendarOptions, googleMeetIntegration, zoomIntegration]);

  return (
    <Sentry.ErrorBoundary fallback={"An error has occured on the Account Settings page"}>
      <Main>
        <SettingsContainer>
          {voiceMessageModal && (
            <RecordVoicemailModalComponent
              onCancel={() => setVoiceMessageModal(false)}
              blinds={voiceMessageModal}
              setBlinds={setVoiceMessageModal}
              refetch={refetch}
            />
          )}
          {/* Disconnect modal for integrations */}
          {showDisconnectIntegrationModal && !!selectedIntegrationDisconnect && <DisconnectIntegrationModal />}

          <SettingsTitleText>Account Settings</SettingsTitleText>
          {(loading && isFirstLoad) || loadingSync ? (
            <Loading />
          ) : error ? (
            <AppErrorText>Error fetching data</AppErrorText>
          ) : (
            <InformationDiv>
              <Formik
                enableReinitialize
                key="details"
                initialValues={{
                  email: data?.fetchUser?.email ?? "",
                  alternate_email: data?.fetchUser?.alternate_email ?? "",
                  phone_number: data?.fetchUser?.phone_number ?? "",
                }}
                validationSchema={detailsSchema}
                onSubmit={(values) => {
                  updateUserSettings({ variables: { ...values } });
                }}
              >
                {({ submitForm, values, dirty, isValid, touched }: FormikProps<DetailsProps>) => {
                  return (
                    <InformationColumn>
                      <SectionDiv>
                        <LabelText>Representative Information</LabelText>
                        <UserInfoContainer>
                          <ProfileContainer>
                            <FlexDiv direction="column" align="center" gap={8} onClick={handleProfileImageUpload}>
                              <ProfileImage
                                borderWidth={10}
                                borderDiameter={124}
                                diameter={118}
                                profileImage={data?.fetchUser?.profile_image}
                              />
                              <FlexDiv
                                gap={8}
                                align="center"
                                style={{
                                  margin: "0px 8px",
                                }}
                              >
                                <PhoenixIcon size={12} svg={edit} variant="brand" pointer />
                                <AppText fontSize={10} fontWeight={500} cursor="pointer" color={theme.PRIMARY600}>
                                  Edit Profile Image
                                </AppText>
                              </FlexDiv>
                            </FlexDiv>
                          </ProfileContainer>
                          <FlexDiv direction="column" gap={8}>
                            <AppText fontSize={14} fontWeight={600}>
                              {localStorage.getItem("user_full_name")}
                            </AppText>
                            <AppText fontSize={12} fontWeight={500}>
                              Twilio Phone #:
                              <span
                                style={{
                                  fontWeight: 400,
                                }}
                              >
                                {" "}
                                {formatPhoneNumber(data?.fetchUser?.phone_number)}
                              </span>
                            </AppText>
                          </FlexDiv>
                        </UserInfoContainer>
                      </SectionDiv>
                      <Divider />
                      <SectionDiv>
                        <LabelText>Account Information</LabelText>
                        <SelectWrapper>
                          <PhoenixInputField
                            name="email"
                            disabled
                            label="Primary Email"
                            titleText="Primary Email"
                            displayNoContextText
                          />
                        </SelectWrapper>
                        {errorUp && <AppErrorText>An error occured when updating</AppErrorText>}
                        <PhoenixAppButton
                          buttonType="secondary"
                          variant="brand-outline"
                          onClick={() => {
                            const tokenFromLocal = localStorage.getItem(TOKEN_PERSIST_KEY);
                            history.push("/private-new-password?token=" + tokenFromLocal);
                          }}
                        >
                          Reset Password
                        </PhoenixAppButton>
                      </SectionDiv>

                      <Divider />
                      {loggedInUser().role !== "ADMIN" &&
                        loggedInUser().role !== "SM" &&
                        (loading && isFirstLoad ? (
                          <Loading />
                        ) : (
                          <SectionDiv>
                            <SectionDiv>
                              {loadingIntegrations ? (
                                <SkeletonIntegrationRow />
                              ) : (
                                <>
                                  <LabelText>Integrations</LabelText>
                                  <IntegrationRow>
                                    {!!gmailSync &&
                                      emailIntegrationsList?.map((integration: IntegrationItem) => (
                                        <IntegrationCardV2
                                          integration={integration}
                                          isConnected={isSynced(integration)}
                                          googleMeetIsEnabledForOrg={googleMeetIsEnabledOnOrg}
                                          zoomIsEnabledForOrg={zoomIsEnabledOnOrg}
                                          integrationLevel="USER"
                                          selectedVideoIntegration={selectedVideoIntegration}
                                        />
                                      ))}

                                    {!!showCalendarOptions &&
                                      calendarIntegrationsList?.map((integration: IntegrationItem) => (
                                        <IntegrationCardV2
                                          integration={integration}
                                          isConnected={isSynced(integration)}
                                          googleMeetIsEnabledForOrg={googleMeetIsEnabledOnOrg}
                                          zoomIsEnabledForOrg={zoomIsEnabledOnOrg}
                                          integrationLevel="USER"
                                          selectedVideoIntegration={selectedVideoIntegration}
                                        />
                                      ))}

                                    {zoomIntegration && zoomIsEnabledOnUser && (
                                      <IntegrationCardV2
                                        integration={ZoomIntegrationItem}
                                        isConnected={isSynced(ZoomIntegrationItem)}
                                        googleMeetIsEnabledForOrg={googleMeetIsEnabledOnOrg}
                                        zoomIsEnabledForOrg={zoomIsEnabledOnOrg}
                                        integrationLevel="USER"
                                        selectedVideoIntegration={selectedVideoIntegration}
                                      />
                                    )}
                                    {googleMeetIntegration && googleMeetIsEnabledOnOrg && (
                                      <IntegrationCardV2
                                        integration={GoogleMeetIntegrationItem}
                                        isConnected={isSynced(GoogleMeetIntegrationItem)}
                                        googleMeetIsEnabledForOrg={googleMeetIsEnabledOnOrg}
                                        zoomIsEnabledForOrg={zoomIsEnabledOnOrg}
                                        integrationLevel="USER"
                                        selectedVideoIntegration={selectedVideoIntegration}
                                      />
                                    )}
                                  </IntegrationRow>
                                </>
                              )}
                            </SectionDiv>
                            <Divider />

                            <SectionDiv>
                              <LabelText>Voicemail Center</LabelText>
                              {voicemail_url && (
                                <audio controls src={voicemail_url}>
                                  Please use Google Chrome for Sellfire!
                                </audio>
                              )}
                              <NewAppButton
                                width={240}
                                variant="secondary"
                                onClick={() => {
                                  setVoiceMessageModal(true);
                                }}
                              >
                                Record New Voicemail
                              </NewAppButton>
                            </SectionDiv>
                            <Divider />
                            <SectionDiv>
                              <LabelText>Audio Settings</LabelText>
                              <SelectWrapper>
                                <PhoenixMultiSelect
                                  titleText="Input"
                                  marginBottom={false}
                                  isMulti={false}
                                  isClearable={false}
                                  options={inputs}
                                  name="input"
                                  value={inputs?.find((input: any) => input?.value === defaultInput)}
                                  placeholder={
                                    inputs?.find((input: any) => input?.value === defaultInput)?.label || "Select..."
                                  }
                                  onChange={(e: any) => {
                                    setSelectedAudio("input");
                                    updateDefaultAudio({
                                      variables: {
                                        default_audio_input: e?.value ?? undefined,
                                        default_audio_output: defaultOutput ?? undefined,
                                      },
                                    });
                                  }}
                                />
                              </SelectWrapper>
                              <SelectWrapper>
                                <PhoenixMultiSelect
                                  titleText="Output"
                                  marginBottom={false}
                                  isMulti={false}
                                  isClearable={false}
                                  options={outputs}
                                  value={outputs?.find((output: any) => output?.value === defaultOutput)}
                                  placeholder={
                                    outputs?.find((output: any) => output?.value === defaultOutput)?.label ||
                                    "Select..."
                                  }
                                  onChange={(e: any) => {
                                    setSelectedAudio("output");
                                    updateDefaultAudio({
                                      variables: {
                                        default_audio_input:
                                          device?.audio?.inputDevice?.deviceId ?? defaultInput ?? undefined,
                                        default_audio_output: e?.value ?? undefined,
                                      },
                                    });
                                  }}
                                  name="output"
                                />
                              </SelectWrapper>
                              <FlexDiv
                                gap={8}
                                align="center"
                                onClick={() => {
                                  getAudioOptions({
                                    showToast: true,
                                  });
                                }}
                              >
                                <PhoenixIcon size={12} svg={refresh} variant="brand" pointer />
                                <AppText fontSize={10} fontWeight={500} cursor="pointer" color={theme.PRIMARY600}>
                                  Refresh Options
                                </AppText>
                              </FlexDiv>
                            </SectionDiv>
                          </SectionDiv>
                        ))}
                      {(process.env.REACT_APP_ENVIRONMENT === "Development" ||
                        process.env.REACT_APP_ENVIRONMENT === "Staging") && (
                        <SectionDiv>
                          <LabelText>Ignore Timezone Contraints</LabelText>
                          <AppText>Configure your available hours to fit within your current time zone</AppText>
                          <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                            <Switch
                              onChange={(checked) => {
                                tzOverride(checked);
                              }}
                              onColor={theme.PRIMARY500}
                              checked={!!tzOverride()}
                              height={19}
                              width={36}
                            />
                            <AppText>Ignore Timezone Constraints</AppText>
                          </div>
                        </SectionDiv>
                      )}
                    </InformationColumn>
                  );
                }}
              </Formik>
            </InformationDiv>
          )}
        </SettingsContainer>
      </Main>
    </Sentry.ErrorBoundary>
  );
};

const SkeletonIntegrationRow = () => {
  return (
    <FlexDiv direction="column" gap={8}>
      <IntegrationRow>
        <SkeletonBlock height={40} width={75} borderRadius={8} />
      </IntegrationRow>
      <IntegrationRow>
        <SkeletonBlock height={200} width={225} borderRadius={8} />
        <SkeletonBlock height={200} width={225} borderRadius={8} />
        <SkeletonBlock height={200} width={225} borderRadius={8} />
      </IntegrationRow>
    </FlexDiv>
  );
};

const SettingsTitleText = styled(AppText)`
  font-size: 22px;
  font-weight: 500;
  color: ${theme.BLACK_COLOR};
  padding-left: 40px;
`;

const UserInfoContainer = styled.section`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
`;

const LabelText = styled(AppText)`
  color: ${theme.BLACK_COLOR};
  font-size: 18px;
  font-weight: 500;
`;

const InformationColumn = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  border-top: 1px solid ${theme.NEUTRAL200};
`;

const InformationDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 0px;
`;

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const Main = styled.div`
  background: ${theme.WHITE_COLOR};
  margin: 28px;
  border-radius: 8px;
  padding: 24px 40px 0px 40px;
  box-sizing: border-box;
  height: 100%;
  overflow-y: auto;
`;

const ProfileContainer = styled.section`
  display: flex;
  flex-direction: column;
`;

const SectionDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  margin: 40px 0px;
`;

const IntegrationRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
`;

const SelectWrapper = styled.div`
  width: 376px;
`;

const Divider = styled.div`
  width: 100%;
  background-color: ${theme.NEUTRAL200};
  height: 1px;
`;

export { Settings };
