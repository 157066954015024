import { gql, useQuery } from "@apollo/client";
import { useFlags } from "launchdarkly-react-client-sdk";
import * as React from "react";
import { useContext, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { loggedInUser } from "src/apollo/cache";
import { createSystemViewNav } from "src/utils/misc";
import styled from "styled-components";
import { ModalContext } from "../../context";
import { theme } from "../../utils/theme";

const ORGANIZATION_DATA = gql`
  query fetchOrganization {
    fetchOrganization {
      id
      show_system_view
      show_rep_my_leads
      show_rep_all_leads
      reps_can_bulk_unassign
    }
  }
`;
const TabNav: React.FC = () => {
  const history = useHistory();
  const { currentTab, setCurrentTab, showTabNav, setShowTabNav, setTabNavData } = useContext(ModalContext);
  const { showLeadPool, voicemailDrops } = useFlags();

  const replaceHistory = (tabName: string) => {
    const currentPath = history.location.pathname;
    let subdirectories = currentPath.split("/");
    history.push(`/${subdirectories[1]}/${tabName}`);
    setCurrentTab(tabName);
  };

  const { data: dataOrg, loading: loadingOrg, error: errorOrg, refetch: refetchOrg } = useQuery(ORGANIZATION_DATA, {
    fetchPolicy: "network-only",
    onError({ message, name }: { message: string; name: string }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });
  const tabNavData = useMemo(() => {
    return createSystemViewNav({
      userRole: loggedInUser().role || "",
      showLeadPool: showLeadPool || false,
      dataOrg: dataOrg || {},
      voicemailDrops: voicemailDrops || false,
    });
  }, [dataOrg, voicemailDrops, showLeadPool, loggedInUser().role]);

  if (!showTabNav) return null;

  return (
    <>
      <TabContainer>
        {tabNavData?.map((tab: any) => (
          <TabDiv thisTab={currentTab === tab.tab_name} onClick={() => replaceHistory(tab.tab_name)}>
            {tab.title}
          </TabDiv>
        ))}
      </TabContainer>
    </>
  );
};

interface ActiveTabProps {
  thisTab: boolean;
}

const TabDiv = styled.div<ActiveTabProps>`
  display: flex;
  align-items: center;

  width: 224px;
  height: 64px;
  padding-left: 24px;

  border-right: ${(props) => (props.thisTab ? `4px solid ${theme.PRIMARY500}` : `1px solid ${theme.NEUTRAL250}`)};
  border-bottom: 1px solid ${theme.NEUTRAL200};
  cursor: pointer;

  font-size: 12px;
  font-weight: ${(props) => (props.thisTab ? "600" : "400")};
  color: ${(props) => (props.thisTab ? theme.PRIMARY500 : theme.BLACK_COLOR)};
  :hover {
    color: ${theme.PRIMARY500};
  }
`;

const TabContainer = styled.div`
  position: absolute;
  left: 72px;
  z-index: 1;

  height: 100%;
  width: max-content;
  overflow-y: auto;
  overflow-x: visible;
  background-color: ${theme.WHITE_COLOR};
`;

export { TabNav };
